
<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row no-gutter justify-center items-center">
            <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
              <div class="px-8 pt-8 login-tabs-container">
                <div class="vx-card__title mb-4 text-center">
                  <a class="w-full mb-3 flex justify-center" :href="absoluteUrl()">
                    <img height="30" :src="absoluteUrl('/images/logos/logo-dark.png')"/>
                  </a>
                </div>
                <vs-tabs>
                  <vs-tab label="Register">
                      <div>
                        <vs-input
                          v-validate="'required'"
                          name="username"
                          icon-no-border
                          icon="icon icon-user"
                          icon-pack="feather"
                          label-placeholder="Username"
                          v-model="data.username"
                          class="w-full"/>
                        <span class="text-danger text-sm" v-show="errors.has('username')">{{ errors.first('username') }}</span>

                        <vs-input
                          v-validate="'required|email|min:4|max:40'"
                          name="email"
                          icon-no-border
                          icon="icon icon-mail"
                          icon-pack="feather"
                          label-placeholder="Email"
                          v-model="data.email"
                          class="w-full"/>
                        <span class="text-danger text-sm" v-show="errors.has('email')">{{ errors.first('email') }}</span>

                        <div class="mt-6">
                          <v-select v-model="data.country_id" label="name" v-validate="{ required: true}" :reduce="item => item.id"  name="country" placeholder="--select country--" :closeOnSelect="true" :options="countries" />
                          <span class="text-danger text-sm"  v-show="errors.has('country')">{{ errors.first('country') }}</span>
                        </div>

                        <div class="mt-6">
                          <v-select v-model="data.skills" multiple label="name" :reduce="item => item.id"  placeholder="--add skills--" :closeOnSelect="false" :options="skills" />
                          <span class="text-danger text-sm"  v-show="errors.has('skills')">{{ errors.first('skills') }}</span>
                        </div>

                        <vs-input
                          v-validate="'required|min:4|max:90'"
                          type="password"
                          name="password"
                          icon-no-border
                          icon="icon icon-lock"
                          icon-pack="feather"
                          label-placeholder="Password"
                          v-model="data.password"
                          @keyup.enter="submitData"
                          class="w-full mt-6" />
                        <span class="text-danger text-sm" v-show="errors.has('password')" >{{ errors.first('password') }}</span>

                        <div class="flex flex-wrap mt-6 mb-3">
                          <vs-button block @click="submitData">Login</vs-button>
                        </div>
                      </div>
                      <div class="mt-4">
                          <p class="text-muted">
                            Already have an account?
                            <router-link to="/login" class="text-primary font-bold ml-1">Sign In</router-link>
                          </p>
                      </div>
                  </vs-tab>
                </vs-tabs>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
<!-- end row -->
</template>

<script>
export default {
  data() {
      return {
          data: {
            username: "",
            email: "",
            country_id: "",
            password: "",
            role:"worker",
            skills: []
          },
          passwordType: "password",
          isInTerms: false,
      };
  },
  computed:{
    countries(){
      return this.$store.state.countries;
    },
    skills(){
      return this.$store.state.skills;
    },
  },
  methods: {
    passwordVisibility(){
      this.passwordType == "text" ? this.passwordType = "password" : this.passwordType = "text"
    },
    submitData(){
      this.$validator.validateAll().then(result => {
        if (!result) { this.alertError("Form Not Valid"); return;}
        this.$vs.loading({color: "#444", type: "material"})
        let formData = new FormData();
        formData.append('data', JSON.stringify(this.data));
        this.$http.post(this.absoluteUrl('/api/register'), formData)
        .then((response)=>{
          this.$vs.loading.close()
          if(response.data.success){
            this.resetForm()
            const {data} =  response.data
            if(data.user.is_verified){
              this.$store.commit("SET_AUTH_TOKEN", data)
            }
            location.replace(data.url? data.url:'/')
          }
        })
      })
    },
    resetForm(){
      this.data = {
        username: "",
        email: "",
        country_id: "",
        password: "",
        role:"worker",
        skills: []
      }
      this.$validator.reset()
    },
  },
  created(){
    if(!this.$store.state.skills.length){
      this.$store.dispatch('fetchSkills')
    }
  }
};
</script>
